import React, { useEffect } from 'react';
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from '../state/appState';

import { PageMeta } from '../components/pageMeta';

import { EnquiryForm } from '../components/forms/enquiryForm';

const Enquiry = ({ data: { page } }) => {

  const { meta } = page || {};

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    toggleHeaderLight(true);
    setHideFooter(true);
  }, [toggleHeaderLight, setHideFooter]);

  return (
    <>
      <PageMeta {...meta} />
      <div className="w-full px-gutter bg-brand text-white min-h-screen">
        <h1 className="font-display text-3xl md:text-4xl lg:text-5xl mb-12 pt-28 md:pt-32 lg:pt-48">
          Visa Enquiry
        </h1>
        <EnquiryForm />
        <div className="w-full border-t border-white py-12 mt-24 lg:mt-60 text-white text-center text-sm flex flex-col md:flex-row justify-between gap-y-6">
          <a
            href="https://www.infrawork.co.nz/"
            target="_blank"
            rel="noreferrer"
            className="font-bold"
          >
            A part of the Infrawork Group
          </a>
          <div className="flex flex-col gap-6 md:flex-row">
            <span>© Visahub 2021. All rights reserved.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enquiry;

export const pageQuery = graphql`
  query formQuery($slug: String!) {
    page: sanityForm(slug: { current: { eq: $slug } }) {      
      meta {
        ...MetaCardFields
      }      
    }
  }
`;
